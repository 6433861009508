import React from 'react'

import { Item } from 'react-photoswipe-gallery'

const styles = {
  img: {
    cursor: 'pointer',
  },
}


const ImageGalleryItem = ({ title, height, width, url }) => {
  return (
    <Item
      original={url}
      thumbnail={url}
      height={height}
      width={width}
    >
      {({ ref, open }) => (
        <div>
          <img css={styles.img} ref={ref} onClick={open} src={url} alt={title} />
        </div>
      )}
    </Item>
  )
}

export default ImageGalleryItem
