import { gql } from '@apollo/client'

export const GET_PHOTOS = gql`
  query GetPhotos($page: String!) {
    photosCollection ( where: { name: $page }) {
      items {
        name
        photoGalleryCollection {
          items {
            title
            description
            width
            height
            url
          }
        }
      }
    }
  }
`
