import React from 'react'
import { useMediaQuery } from 'react-responsive'

const useStyles = (isDesktop) => {
  const GUTTER_SIZE = isDesktop ? 32 : 16
  return {
    root: {
      margin: '0 auto',
      maxWidth: 1280,
      padding: `${GUTTER_SIZE}px`,
      width: `calc(100% - ${GUTTER_SIZE * 2}px)`,
    }
  }
}


const Page = ({ children }) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  })

  const styles = useStyles(isDesktop)

  return (
    <main css={styles.root}>
      {children}
    </main>

  )
}

export default Page
