import React from 'react'
import Masonry from 'react-masonry-css'
import { Global, css } from '@emotion/react'
import { useMediaQuery } from 'react-responsive'

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery } from 'react-photoswipe-gallery'

import ImageGalleryItem from './ImageGalleryItem'


const breakpointColumnsObj = {
  default: 3,
  767: 2,
};

const ImageGallery = ({ items }) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  })

  const GUTTER_SIZE = isDesktop ? 32 : 16

  return (
    <>
      <Global styles={css`
        .my-masonry-grid {
          display: flex;
          margin-left: -${GUTTER_SIZE}px; /* gutter size offset */
          width: auto;
        }
        .my-masonry-grid_column {
          padding-left: ${GUTTER_SIZE}px; /* gutter size */
          background-clip: padding-box;
        }

        /* Style your items */
        .my-masonry-grid_column div { /* change div to reference your elements you put in <Masonry> */
          background: grey;
          margin-bottom: ${GUTTER_SIZE}px;
        }
        .my-masonry-grid_column div img {
          display: block;
          width: 100%;
        }
      `} />
      <Gallery options={{
        bgOpacity: '0.7',
        shareEl: false,
      }}>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
          >
          {items.map((item) => {
            return <ImageGalleryItem url={item.url} height={item.height} width={item.width} title={item.title} key={item.title} />
          })}
        </Masonry>
      </Gallery>
    </>
  )
}

export default ImageGallery
