import React from 'react'
import { Link } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fa500px, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

const styles = {
  root: {
    backgroundColor: '#FFF',
    borderTop: '1px solid #dbdbd5',
    height: 50,
    position: 'sticky',
    top: 0
  },
  inner: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    margin: '0 auto',
    maxWidth: 1280,
    padding: '0 16px',
    width: 'calc(100% - 32px)',

    [`@media (min-width: 768px)`]: {
      padding: '0 32px',
      width: 'calc(100% - 64px)',
    }
  },
  copyright: {
    color: '#262626',
    fontSize: '13px',
  },
  nav: {
    ul: {
      display: 'flex',
      listStyle: 'none',
      margin: 0,
      padding: 0,

      li: {
        display: 'block',
        marginLeft: 16,
      }
    },
  }
}

const Footer = () => {
  return (
    <div css={styles.root}>
      <div css={styles.inner}>
        <div css={styles.copyright}>hello@onedavila.com   |   &copy; Juan Davila. 2021</div>
        <nav css={styles.nav}>
          <ul>
            <li>
              <a target="_blank" title="Linkedin" href="https://ca.linkedin.com/in/juandav">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </li>
            <li>
              <a target="_blank" title="Instagram" href="http://instagram.com/onedavila">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a target="_blank" title="500px" href="https://500px.com/onedavila">
                <FontAwesomeIcon icon={fa500px} />
              </a>
            </li>
					</ul>
        </nav>
      </div>
    </div>

  )
}

export default Footer
